import axios from "axios";
import { Configuration } from "typescript-axios";
import cookie from "cookie"; // eslint-disable-line

export const API_URL = import.meta.env.VITE_API_HOST;
export function getAxiosParams(basePath = API_URL) {
  return new Configuration({ basePath });
}


if (typeof window !== 'undefined') {
  axios.interceptors.response.use(
    (response) => {
      if (
        (response.config.url?.includes("/api/v1/auth") ||
          response.config.url?.includes("/api/v1/auth-email") ||
          response.config.url?.includes("/api/v1/auth-facebook")) &&
        response.status === 200
      ) {
        if (response.data.token) {
          document.cookie = cookie.serialize("token", response.data.token, {
            path: "/", // make it accessible from all pages
            maxAge: 60 * 60 * 24 * 29, // 29 days
          });
        }
        if (response.data.is_user_created) {
          document.cookie = cookie.serialize("isUserCreated", response.data.is_user_created, {
            path: "/", // make it accessible from all pages
            maxAge: 60 * 60 * 24 * 1, // 1 days
          });
        }
      }
      return response;
    },
    (error) => {
      if (error.code === "ECONNABORTED" || (error.request && !error.response)) {
        // Ignore ECONNABORTED errors
        if(error.code === "ECONNABORTED") {
          console.debug("Request timed out");
        }
        return new Promise(() => { });
      }
      return Promise.reject(error); // Any other error should be handled
    }
  );
  axios.interceptors.request.use((config) => {
    const token = cookie.parse(document.cookie).token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
}

export default axios;
